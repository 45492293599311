import React, { useMemo } from 'react';
import classNames from 'classnames';

import EmailIcon from '@uq-assets/email.svg';

import { UqLink, UqNavSocial, UqFooterNavbarV2, UqFooterNavResources } from '../../navigation';
import { UqLogo } from '../../unitq-logo';
import { UqAwards } from '../../awards';
import { UqContainerV2 } from '../container';

import * as styles from './footer.module.scss';

const UQ_EMAIL = 'hello@unitq.com';

export function UqFooterV2() {
  const copyrightYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <footer className={styles.root}>
      <UqContainerV2
        className={styles.navContainer}
        theme="dark"
      >
        <section className={styles.about}>
          <UqLogo className={styles.logo} />
          <UqNavSocial />

          <address className="body-2">
            <span className={styles.company}>
              unitQ HQ
            </span>
            <br />
            501 2nd St. <br />
            San Francisco, CA 94107
          </address>

          <div className={styles.contact}>
            <UqLink
              to={`mailto: ${UQ_EMAIL}`}
              external
              hideArrow
              variant="inherit"
            >
              <EmailIcon className={styles.emailIcon} />
              <span className="body-2">
                Contact Us
              </span>
            </UqLink>
          </div>

          <UqAwards />
        </section>

        <UqFooterNavbarV2 />
      </UqContainerV2>

      <nav>
        <UqContainerV2
          className={styles.resourcesContainer}
          theme="dark"
        >
          <UqLink
            className={classNames('caption-2', styles.copyright)}
            to="/legal-copyright"
            hideArrow
            variant="inherit"
          >
            © {copyrightYear} unitQ. All rights reserved
          </UqLink>

          <UqFooterNavResources />
        </UqContainerV2>
      </nav>
    </footer>
  );
}
